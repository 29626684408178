import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Header from "./header/header";
import Footer from "./footer/footer";

import LoginPage from "../authentication/login-page/login-page";

import "./app.css";
import AppContext from "./app-context";
import PasswordRequestPage from "../authentication/password-request-page/password-request-page";
import PasswordResetPage from "../authentication/password-reset-page/password-reset-page";
import LogoutPage from "../authentication/logout-page/logout-page";
import Dashboard from "../dashboard/dashboard";
import NotificationContainer from "./notification/notification-container";
import Calculation from "../calculation/calculation";
import BaseComponent from "./base-component";
import ApiClient from "../api-client/api-client";
import QuoteManagementPage from "../quote-management/quote-management-page";
import OrderManagementPage from "../order-management/order-management";
import ContactManagementPage from "../contact-management/contact-management-page";
import AccountManagementPage from "../account-management/account-management-page";
import TenantManagementPage from "../tenant-management/tenant-management-page";
import Loader from "./loader/loader";
import Popup from "./popup/popup";

class App extends BaseComponent {
  constructor(props) {
    super(props);
    var user = null;
    try {
      user = JSON.parse(localStorage.getItem("user"));
    } catch (error) {
      localStorage.setItem("user", null);
    }

    var language = null;
    try {
      language = JSON.parse(localStorage.getItem("language"));
    } catch (error) {
      localStorage.setItem("language", null);
    }

    this.state = {
      settings: {
        ...require("./app-settings.json"),
        apiUrl: process.env.REACT_APP_API_URL,
        apiClient: process.env.REACT_APP_API_CLIENT,
        apiSecret: process.env.REACT_APP_API_SECRET,
      },
      user: user,
      userHasClaim: this.userHasClaim.bind(this),
      language: language,
      translations: {},
      theme: null,
      tenantSettings: null,
      loading: false,
      addNotification: this.addNotification.bind(this),
      setLoading: this.setLoading.bind(this),
      setUser: this.setUser.bind(this),
    };

    this.notificationContainerRef = React.createRef();
  }

  userHasClaim(type, value) {
    return !!this.state.user.claims
      .find(c => c.type === type && c.value === value);
  }

  componentDidMount() {
    window.onunhandledrejection = (e) => {
      this.addNotification(
        "error",
        this.state.translations.notifications_unknown_error
      );
    };

    new ApiClient(this.state)
      .call(
        "GET",
        "/v1/TenantTheme/Styling",
        null, false, {},
        {
          domain: window.location.hostname,
        }
      )
      .then((result) => {
        if (result.ok) {
          let theme = result.json;

          let themeColorEl = document.createElement('meta');
          themeColorEl.name = 'theme-color';
          themeColorEl.content = theme.primaryColor;
          document.head.appendChild(themeColorEl);

          if (theme.favicon) {
            let faviconEl = document.createElement('link');
            faviconEl.rel = 'icon';
            faviconEl.href = this.state.settings.apiUrl + theme.favicon.filePath;
            document.head.appendChild(faviconEl);
          }

          let titleEl = document.createElement('title');
          titleEl.innerText = `${theme.tenantName} Webshop`;
          document.head.appendChild(titleEl);

          return this.setStateAsync({
            theme: theme,
          });
        }
        else {
          return this.setStateAsync({
            theme: false,
          });
        }
      }).then(this.getTenantSettings.bind(this));
  }

  setLoading(loading) {
    return this.setStateAsync({ loading: loading });
  }

  addNotification(severity, message, time = 10) {
    this.notificationContainerRef.current.addNotification(
      severity,
      message,
      time
    );
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));

    return this.setStateAsync({
      user: user,
    }).then(() => {
      if (user) {
        return this.setLanguage(user.userLanguage);
      }
    })
      .then(this.getTenantSettings.bind(this));
  }

  getTenantSettings() {
    if (this.state.user) {
      return new ApiClient(this.state).call(
        'GET',
        `/v1/Tenant/${this.state.theme.tenantId}/TenantSettings/`
      )
        .then((result) => {
          if (result.ok) {
            return this.setStateAsync({
              tenantSettings: result.json,
            });
          }
        });
    }
  }

  setLanguage(language) {
    localStorage.setItem("language", JSON.stringify(language));

    return this.setStateAsync({
      language: language,
    })
      .then(() => {
        return new ApiClient(this.state).call(
          "GET",
          "/v1/GlobalTranslation/Dictionary"
        );
      })
      .then((result) => {
        if (result.ok) {
          let translations = result.json;

          return this.setStateAsync({
            translations: translations,
          });
        }
      });
  }

  render() {
    if (this.state.theme === false) {
      return (
        <Popup
          title={'Webshop not configured'}
        >
          <div className="popup-section">
            <div className="popup-column">It looks like the DNS records for this domain are set up correctly. This domain is however not linked to a tenant.</div>
          </div>
        </Popup>
      )
    }

    if (!this.state.theme
      || (this.state.user && !this.state.tenantSettings)) {
      return (
        <div
          className="app"
          style={{
            '--secondary-color': '#aaa',
            'height': '100vh',
          }}
        >
          <Loader />
          <NotificationContainer ref={this.notificationContainerRef} />
        </div>
      )
    }

    let translationsLoaded = Object.keys(this.state.translations).length > 0;
    let theme = this.state.theme;

    return (
      <div
        className="app"
        style={{
          "--primary-color": theme.primaryColor,
          "--secondary-color": theme.secondaryColor,
          "--secondary-color-light": `${theme.secondaryColor}22`,
        }}
      >
        <AppContext.Provider value={this.state}>
          <Router>
            <Header setLanguage={this.setLanguage.bind(this)} />
            <div className="app-pageContainer">
              {!translationsLoaded &&
                <Loader />
              }
              {translationsLoaded && !this.state.user &&
                <Switch>
                  <Route path="/login">
                    <LoginPage setUser={this.setUser.bind(this)} />
                  </Route>
                  <Route path="/password-request">
                    <PasswordRequestPage />
                  </Route>
                  <Route path="/password-reset">
                    <PasswordResetPage />
                  </Route>
                  <Route path="/">
                    <Redirect to="/login" />
                  </Route>
                </Switch>
              }
              {translationsLoaded && this.state.user &&
                <Switch>
                  <Route path="/logout">
                    <LogoutPage setUser={this.setUser.bind(this)} />
                  </Route>
                  <Route path="/dashboard">
                    <Dashboard />
                  </Route>
                  <Route path="/calculate">
                    <Calculation />
                  </Route>
                  <Route path="/quotes">
                    <QuoteManagementPage />
                  </Route>
                  <Route
                    path="/account/:id/quotes"
                    render={(props) => (
                      <QuoteManagementPage accountId={props.match.params.id} />
                    )}
                  ></Route>
                  <Route path="/orders">
                    <OrderManagementPage />
                  </Route>
                  <Route
                    path="/account/:id/orders"
                    render={(props) => (
                      <OrderManagementPage accountId={props.match.params.id} />
                    )}
                  ></Route>
                  <Route
                    path="/tenant/:tenantId/account/:accountId/contacts"
                    render={(props) => (
                      <ContactManagementPage tenantId={props.match.params.tenantId} accountId={props.match.params.accountId} />
                    )}
                  ></Route>
                  <Route
                    path="/tenant/:id/accounts"
                    render={(props) => (
                      <AccountManagementPage tenantId={props.match.params.id} />
                    )}
                  ></Route>
                  <Route path="/tenants">
                    <TenantManagementPage />
                  </Route>
                  <Route path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                </Switch>
              }
            </div>
            <Footer />
            <NotificationContainer ref={this.notificationContainerRef} />
          </Router>
        </AppContext.Provider>
      </div>
    );
  }
}

export default App;
